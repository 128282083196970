import React from 'react';
import { Drawer } from 'antd';
import { setDisplayMobileMenu } from 'reduxStore/slices/contact.slice';
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks';
import headerLogo from 'assets/images/logos/logo-white-blue.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ActionButton from 'components/ui/buttons/action-button';
import { setDisplayModal } from 'reduxStore/slices/contact.slice';

export default function MenuMovil() {
    const dispatch = useAppDispatch();
    const displayMobileMenu = useAppSelector((state) => state.contactSlice.displayMobileMenu);
    const { t } = useTranslation();
    const menuItems = [
        {
            name: 'menu.home',
            url: '/',
        },
        {
            name: 'menu.services',
            url: '/services',
        },

        {
            name: 'menu.about',
            url: '/about',
        },
        {
            name: 'menu.politics',
            url: '/politics',
        },
    ];

    const onClose = () => {
        dispatch(setDisplayMobileMenu(false));
    };

    return (
        <Drawer title="Basic Drawer" onClose={onClose} open={displayMobileMenu}>
            <div
                className="nv__header__logo nv__header__logo--mobile"
                onClick={() => {
                    onClose();
                }}
            >
                <Link to="/#home">
                    <img src={headerLogo} alt="Logo Narval white blue" />
                </Link>
            </div>

            <section>
                <ul
                    className="nv__header__menu nv__header__menu__mobile"
                    onClick={() => {
                        onClose();
                    }}
                >
                    {menuItems.map((item) => {
                        return (
                            <li key={item.name}>
                                <Link to={item.url}> {t(item.name)}</Link>
                            </li>
                        );
                    })}
                </ul>

                <ActionButton
                    text={t('menu.contact')}
                    type="rounded"
                    onClick={() => {
                        onClose();
                        dispatch(setDisplayModal(true));
                    }}
                />
            </section>
        </Drawer>
    );
}
