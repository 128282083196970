import React, { useEffect } from 'react';
import MainSlider from 'components/generals/main-slider';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Animated from 'components/generals/AnimatedComponent';
import NV__logo from 'components/generals/logo';
import logoDonaStella from 'assets/images/logos/estela.png';
export default function PreloadPage() {
    const { pathname } = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <main className="main__page main__page--full nv__preload">
                <section className="nv__preload__content">
                    <Animated animation={'animate__fadeInUp'}>
                        <div className="nv__preload__logo">
                            <img src={logoDonaStella} />
                        </div>
                    </Animated>

                    <p>{t('donna.paragraph1')}</p>
                    <p>{t('donna.paragraph2')}</p>
                    <p>{t('donna.paragraph3')}</p>
                    <p>{t('donna.paragraph4')}</p>
                    <p>{t('donna.paragraph5')}</p>
                </section>
            </main>
        </>
    );
}
