import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import config from 'config';
export default function LanguangeSelector() {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState('en');

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        console.log('Language changed to: ' + lng);
        setLanguage(lng);
    };

    return (
        <div className="nv__language__selector transition">
            <>
                {language === 'es' ? (
                    <div
                        className="nv__language__selector__flag"
                        onClick={() => changeLanguage('en')}
                    >
                        <img src={`${config.SITE_URL}/assets/languages/es.jpg`} alt="English" />
                    </div>
                ) : (
                    <>
                        <div
                            className="nv__language__selector__flag"
                            onClick={() => changeLanguage('es')}
                        >
                            <img src={`${config.SITE_URL}/assets/languages/en.jpg`} alt="Spanish" />
                        </div>
                    </>
                )}
            </>
        </div>
    );
}
